import { createSlice } from '@reduxjs/toolkit';

const textSlice = createSlice({
  name: 'text',
  initialState: {
    user: '',
    qrCode: '',
    successMessage: '',
    mobileShowQrCode: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setQrCode: (state, action) => {
      state.qrCode = action.payload;
      state.mobileShowQrCode = true;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    goBackToEditOnMobile: (state) => {
      state.mobileShowQrCode = false;
    },
  },
});

export const { setUser, setQrCode, setSuccessMessage, goBackToEditOnMobile } =
  textSlice.actions;

export default textSlice.reducer;
