import React, { useState } from 'react';
import { setQrCode } from '../Redux/textSlice';
import { useDispatch } from 'react-redux';

import './vcard.css';
import { allEmpty } from '../../utils';
import { GenerateButton } from '../GenerateButton/GenerateButton';

const VCARD = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    mobileNumber: '',
    fax: '',
    emailAddress: '',
    company: '',
    title: '',
    website: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (allEmpty(...Object.values(formData))) {
      const { default: Swal } = await import(
        /* webpackPrefetch: true */ 'sweetalert2'
      );
      Swal.fire({
        icon: 'info',
        text: 'Please fill in at least one field',
        confirmButtonColor: '#6C95FF',
      });
      return;
    }

    const addressData = {
      street: formData.streetAddress || '',
      city: formData.city || '',
      state: formData.state || '',
      zip: formData.zip || '',
      country: formData.country || '',
    };

    const vCardString = `BEGIN:VCARD
VERSION:3.0
N:${formData.lastName || ''};${formData.firstName || ''};;;
TITLE:${formData.title || ''}
ADR;TYPE=WORK:;;${addressData.street};${addressData.city};${
      addressData.state
    };${addressData.zip};${addressData.country}
TEL;TYPE=WORK:${formData.phoneNumber || ''}
TEL;TYPE=CELL:${formData.mobileNumber || ''}
TEL;TYPE=FAX:${formData.fax || ''}
TEL;TYPE=WORK:${formData.phoneNumber || ''}
TEL;TYPE=CELL:${formData.mobileNumber || ''}
TEL;TYPE=FAX:${formData.fax || ''}
EMAIL:${formData.emailAddress || ''}
URL:${formData.website || ''}
ORG:${formData.company || ''}
END:VCARD`;

    dispatch(setQrCode(vCardString));
  };

  return (
    <div className="App">
      <label className="v-card-block-title">Enter Contact Information</label>

      <div className="input-row">
        <input
          type="text"
          placeholder="First Name"
          className={`input-1 first-input`}
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Last Name"
          className={`input-1`}
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
      </div>
      <div className="input-row">
        <input
          type="text"
          placeholder="Phone Number"
          className={`input-1`}
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Mobile Number"
          className={`input-1`}
          name="mobileNumber"
          value={formData.mobileNumber}
          onChange={handleChange}
        />
      </div>
      <div className="input-row">
        <input
          type="text"
          placeholder="Fax"
          className={`input-1`}
          name="fax"
          value={formData.fax}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Email Address"
          className={`input-1`}
          name="emailAddress"
          value={formData.emailAddress}
          onChange={handleChange}
        />
      </div>
      <div className="input-row">
        <input
          type="text"
          placeholder="Company"
          className={`input-1`}
          name="company"
          value={formData.company}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Title"
          className={`input-1`}
          name="title"
          value={formData.title}
          onChange={handleChange}
        />
      </div>
      <div className="input-row">
        <input
          type="text"
          placeholder="Website"
          className={`input-2`}
          name="website"
          value={formData.website}
          onChange={handleChange}
        />
      </div>
      <div className="input-row">
        <input
          type="text"
          placeholder="Street Address"
          className={`input-2`}
          name="streetAddress"
          value={formData.streetAddress}
          onChange={handleChange}
        />
      </div>
      <div className="input-row">
        <input
          type="text"
          placeholder="City"
          className={`input-1`}
          name="city"
          value={formData.city}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="State"
          className={`input-1`}
          name="state"
          value={formData.state}
          onChange={handleChange}
        />
      </div>
      <div className="input-row">
        <input
          type="text"
          placeholder="ZIP"
          className={`input-1`}
          name="zip"
          value={formData.zip}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Country"
          className={`input-1`}
          name="country"
          value={formData.country}
          onChange={handleChange}
        />
      </div>

      <GenerateButton onClick={handleSubmit} />
    </div>
  );
};

export default VCARD;
