import React, { useState } from 'react';

import { setQrCode } from '../Redux/textSlice';
import { useDispatch } from 'react-redux';
import './QRSMS.css';
import { GenerateButton } from '../GenerateButton/GenerateButton';

const QRSMS = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const generateQRCode = async () => {
    if (!phoneNumber) {
      const { default: Swal } = await import(
        /* webpackPrefetch: true */ 'sweetalert2'
      );
      Swal.fire({
        icon: 'info',
        text: 'Please enter phone number',
        confirmButtonColor: '#6C95FF',
      });
      return;
    }

    const qrCode = `SMSTO:${phoneNumber}:${message}`;
    dispatch(setQrCode(qrCode));
  };

  return (
    <div className="form">
      <div className="form-group">
        <label htmlFor="field2" className="label">
          Enter Phone Number
        </label>
        <input
          type="text"
          id="field2"
          className={`input `}
          placeholder="+12813308004"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="message" className="label">
          Enter Message
        </label>
        <textarea
          id="message"
          className={`textarea `}
          rows={6}
          value={message}
          onChange={handleMessageChange}
        />
      </div>
      <GenerateButton onClick={generateQRCode} />
    </div>
  );
};

export default QRSMS;
