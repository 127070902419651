import React, { useCallback, useEffect, useState } from 'react';
import { setQrCode } from '../Redux/textSlice';
import { useDispatch } from 'react-redux';
import './text.css';
import { isMobileLayout } from '../../utils';
import { GenerateButton } from '../GenerateButton/GenerateButton';

const TextForQr = () => {
  const [text, setText] = useState('');

  const onTextChange = (e) => {
    setText(e.target.value);
  };

  const dispatch = useDispatch();
  const generateQr = useCallback(async () => {
    if (text) {
      dispatch(setQrCode(text));
    }
  }, [text, dispatch]);

  const onMobileSubmit = async () => {
    if (!text) {
      const { default: Swal } = await import(
        /* webpackPrefetch: true */ 'sweetalert2'
      );
      Swal.fire({
        icon: 'info',
        text: 'Please enter text',
        confirmButtonColor: '#6C95FF',
      });
      return;
    }
    generateQr();
  };

  useEffect(() => {
    const generateButtonVisible = isMobileLayout();
    if (!generateButtonVisible) generateQr();
  }, [generateQr]);

  return (
    <div className="text-editor">
      <label htmlFor="text-area">Enter Text</label>
      <textarea
        id="text-area"
        className="text-area"
        style={{ width: '100%' }}
        value={text}
        onChange={onTextChange}
      />
      <div className="genCode">
        <GenerateButton onClick={onMobileSubmit} />
      </div>
    </div>
  );
};

export default TextForQr;
