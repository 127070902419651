import { IoArrowBack } from 'react-icons/io5';
import React from 'react';
import './BackButton.css';

export function BackButton({ onClick }) {
  return (
    <div className="back-button-wrapper">
      <button className="back-button" onClick={onClick}>
        {/*
         color=black is required to fix blue icon color on Safari
         https://imgur.com/a/qu1vprA
        */}
        <IoArrowBack size="25px" color="black" />
      </button>
    </div>
  );
}
