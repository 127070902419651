import React, { useState } from 'react';

import { setQrCode } from '../Redux/textSlice';
import { useDispatch } from 'react-redux';
import './email.css';
import { GenerateButton } from '../GenerateButton/GenerateButton';

const EmailForQR = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'subject') {
      setSubject(value);
    } else if (name === 'message') {
      setMessage(value);
    }
  };

  const generateQRCode = async () => {
    if (!email) {
      const { default: Swal } = await import(
        /* webpackPrefetch: true */ 'sweetalert2'
      );
      Swal.fire({
        icon: 'info',
        text: 'Please enter email address',
        confirmButtonColor: '#6C95FF',
      });
      return;
    }

    const qrCode = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)}`;

    dispatch(setQrCode(qrCode));
  };

  return (
    <div className="form">
      <div className="form-group">
        <label htmlFor="field1" className="label">
          Enter Email Address
        </label>
        <input
          type="text"
          id="field1"
          className={`input`}
          name="email"
          value={email}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="field2" className="label">
          Enter Subject
        </label>
        <input
          type="text"
          id="field2"
          className={`input `}
          name="subject"
          value={subject}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="message" className="label">
          Enter Message
        </label>
        <textarea
          id="message"
          className={`textarea`}
          rows={6}
          name="message"
          value={message}
          onChange={handleInputChange}
        />
      </div>

      <GenerateButton onClick={generateQRCode} />
    </div>
  );
};

export default EmailForQR;
