import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import textReducer from '../src/Component/Redux/textSlice';

const store = configureStore({
  reducer: {
    text: textReducer, // Add your reducer here
  },
});

const reactRoot = window.reactRoot || document.getElementById('root');

const root = ReactDOM.createRoot(reactRoot);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
