import React from 'react';
import './GenerateButton.css';

export function GenerateButton({ onClick }) {
  return (
    <button className="generate-qr-code-button" onClick={onClick}>
      Generate QR code
    </button>
  );
}
