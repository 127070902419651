/**
 * @param {ImageData} imageData
 * @return {Promise<{blob: Blob, dataUrl: string}>}
 */
function imageDataToDataUrlAndBlob(imageData) {
  const w = imageData.width;
  const h = imageData.height;
  const canvas = document.createElement('canvas');
  canvas.width = w;
  canvas.height = h;
  const ctx = canvas.getContext('2d');
  ctx.putImageData(imageData, 0, 0);

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      const dataUrl = canvas.toDataURL('image/png');
      resolve({ blob, dataUrl });
    });
  });
}

/**
 * @param {CanvasRenderingContext2D} ctx
 * @param {number} x
 * @param {number} y
 * @param {number} width
 * @param {number} height
 * @param {number} radius
 */
function drawRoundedRect(ctx, x, y, width, height, radius) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}

/**
 * @param {string} base64Image
 * @return {Promise<{blob: Blob, dataUrl: string}>}
 */
export function getImageCenter(base64Image) {
  return new Promise((resolve) => {
    const image = new Image();

    image.onload = async function () {
      const width = image.width;
      const height = image.height;

      const centerX = width / 2;
      const centerY = height / 2;

      const rectSize = Math.min(width, height);
      const halfRectSize = rectSize / 2;
      const rectX = centerX - halfRectSize;
      const rectY = centerY - halfRectSize;

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const context = canvas.getContext('2d');

      drawRoundedRect(context, rectX, rectY, rectSize, rectSize, rectSize / 6);
      context.clip();
      context.drawImage(image, 0, 0);

      const croppedImageData = context.getImageData(
        rectX,
        rectY,
        rectSize,
        rectSize
      );

      const result = await imageDataToDataUrlAndBlob(croppedImageData);
      resolve(result);
    };

    image.src = base64Image;
  });
}

/**
 * @param {string} url
 * @return {string}
 */
export function getUrlWithPrefix(url) {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }

  return `https://${url}`;
}
