import React from 'react';
import './DownloadButtons.css';
import { ReactComponent as DownloadIcon } from '../../../Assets/download-icon.svg';

export function DownloadButtons({ disabled, onDownloadPng, onDownloadSvg }) {
  const Icon = <DownloadIcon fill={disabled ? 'white' : 'black'} />;

  return (
    <div className="download-buttons">
      <button disabled={disabled} onClick={onDownloadPng}>
        {Icon}
        <span>Download PNG</span>
      </button>
      <button disabled={disabled} onClick={onDownloadSvg}>
        {Icon}
        <span>Download SVG</span>
      </button>
    </div>
  );
}
