import './App.css';
import QRStep2 from './Component/QRStep2/QRStep2';

function App() {
  return (
    <div className="App">
      <QRStep2 />
    </div>
  );
}

export default App;
