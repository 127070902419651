import React, { useState } from 'react';
import { setQrCode } from '../Redux/textSlice';
import { useDispatch } from 'react-redux';
import './wifi.css';
import { GenerateButton } from '../GenerateButton/GenerateButton';

function escapeWiFiChars(str) {
  // Special characters \,;": should be escaped with a backslash (\) as in MECARD encoding.
  // https://github.com/zxing/zxing/wiki/Barcode-Contents#wi-fi-network-config-android-ios-11
  return str
    .replace(/\\/g, '\\\\')
    .replace(/,/g, '\\,')
    .replace(/;/g, '\\;')
    .replace(/"/g, '\\"')
    .replace(/:/g, '\\:');
}

function generateWiFiQrCodeString(networkName, securityType, password, hidden) {
  networkName = escapeWiFiChars(networkName);
  password = escapeWiFiChars(password);
  // QR contents spec:
  // https://github.com/zxing/zxing/wiki/Barcode-Contents#wi-fi-network-config-android-ios-11
  // Sample: WIFI:T:WPA;S:mynetwork;P:mypass;;
  return `WIFI:S:${networkName};T:${securityType};P:${password};${
    hidden ? 'H:true;' : ''
  };`;
}

const WifiQR = () => {
  const dispatch = useDispatch();

  const [networkName, setNetworkName] = useState('');
  const [securityType, setSecurityType] = useState('WPA');
  const [password, setPassword] = useState('');
  const [hidden, setHidden] = useState(false);

  const handleInput1Change = (event) => {
    setNetworkName(event.target.value);
  };

  const handleInput2Change = (event) => {
    setSecurityType(event.target.value);
  };

  const handleInput3Change = (event) => {
    setPassword(event.target.value);
  };

  const handleCheckbox2Change = (event) => {
    setHidden(event.target.checked);
  };

  const generateQRCode = async () => {
    const { default: Swal } = await import(
      /* webpackPrefetch: true */ 'sweetalert2'
    );

    if (!networkName && !password) {
      Swal.fire({
        icon: 'info',
        text: 'Please enter the network name and password',
        confirmButtonColor: '#6C95FF',
      });
      return;
    }

    if (!networkName) {
      Swal.fire({
        icon: 'info',
        text: 'Please enter the network name',
        confirmButtonColor: '#6C95FF',
      });
      return;
    }
    if (!password) {
      Swal.fire({
        icon: 'info',
        text: 'Please enter the password',
        confirmButtonColor: '#6C95FF',
      });
      return;
    }

    const qrCode = generateWiFiQrCodeString(
      networkName,
      securityType,
      password,
      hidden ? 'hidden' : ''
    );

    dispatch(setQrCode(qrCode));
  };

  return (
    <div className="my-component">
      <div className="input-row-wifi">
        <div className="wifiInput-main">
          <div className="wifi-text">Network Name</div>
          <input
            type="text"
            placeholder="SSID"
            className={`wifiInput`}
            value={networkName}
            onChange={handleInput1Change}
          />
        </div>
        <div className="wifiInput-main">
          <div className="wifi-text">Encryption Type</div>
          <div className="custom-dropdown">
            <select
              className={`wifiInput `}
              value={securityType}
              onChange={handleInput2Change}
            >
              <option value="WPA" className="option-text">
                WPA3/WPA2/WPA
              </option>
              <option value="WEP" className="option-text">
                WEP
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className="input-row-wifi">
        <div className="wifiInput-main">
          <div className="wifi-text">WiFi Password</div>
          <input
            type="text"
            className={`wifiInput `}
            value={password}
            onChange={handleInput3Change}
          />
        </div>
        <div className="check-input">
          <div className="wifi-text" style={{ borderRadius: '20%' }}>
            Hidden Network
          </div>
          <input
            type="checkbox"
            className="check-input1"
            checked={hidden}
            onChange={handleCheckbox2Change}
          />
        </div>
      </div>
      <GenerateButton onClick={generateQRCode} />
    </div>
  );
};

export default WifiQR;
