import { useEffect, useState } from 'react';

/**
 * https://github.com/jamiebuilds/react-loadable#avoiding-flash-of-loading-component
 */
export function SmartLoading({ children = 'Loading...' }) {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 200);
  }, []);
  return visible ? children : null;
}
