import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import logoImage from '../../Assets/c1.svg';
import C1 from '../../Assets/c1.svg';

import './step2.css';
import Url from '../../Assets/url1.svg';
import Vcard from '../../Assets/vcard1.svg';
import E4 from '../../Assets/e4.svg';
import Text from '../../Assets/text1.svg';
import SMS from '../../Assets/sms1.svg';
import Email from '../../Assets/email1.svg';
import Wifi from '../../Assets/wifi1.png';

import QR from '../../Assets/blurQr.svg';
import Plus from '../../Assets/plus.png';
import VCARD from '../VCARD/VCARD';
import TextForQr from '../TEXT/TextForQr';
import EmailForQR from '../EmalForQR/EmailForQR';
import WifiQR from '../WifiQR/WifiQR';
import QRSMS from '../QRSMS/QRSMS';
import P1 from '../../Assets/P1.svg';

import P2 from '../../Assets/P2.svg';
import P3 from '../../Assets/P3.svg';
import P4 from '../../Assets/P4.svg';
import P5 from '../../Assets/P5.svg';
import P6 from '../../Assets/P6.svg';
import P8 from '../../Assets/P7.svg';
import P7 from '../../Assets/P8.svg';
import P9 from '../../Assets/P9.svg';

import E1 from '../../Assets/e1.svg';
import E2 from '../../Assets/e2.svg';
import E3 from '../../Assets/e3.svg';
import C2 from '../../Assets/url.svg';
import C3 from '../../Assets/vcard.svg';
import C4 from '../../Assets/text.svg';
import C5 from '../../Assets/sms.svg';
import C6 from '../../Assets/email.svg';
import C7 from '../../Assets/wifi.svg';

import C8 from '../../Assets/scanme.svg';
import C9 from '../../Assets/insta.svg';
import C10 from '../../Assets/tiktok.svg';
import C11 from '../../Assets/fb.svg';
import C12 from '../../Assets/youtube.svg';
import C13 from '../../Assets/snap.svg';
import C14 from '../../Assets/pi.svg';
import C15 from '../../Assets/linkined.svg';
import C16 from '../../Assets/veta.svg';
import C17 from '../../Assets/paypal.svg';
import C19 from '../../Assets/map.svg';

import Tw from '../../Assets/twiter.svg';
import Pay2 from '../../Assets/pay2.svg';

import ST from '../../Assets/st.svg';
import Close from '../../Assets/close.png';
import F3 from '../../Assets/f2.svg';
import F2 from '../../Assets/f3.svg';
import F4 from '../../Assets/f4.svg';
import { useDispatch, useSelector } from 'react-redux';
import { goBackToEditOnMobile, setQrCode } from '../Redux/textSlice';
import { getImageCenter, getUrlWithPrefix } from './qrStep2Utils';
import { GenerateButton } from '../GenerateButton/GenerateButton';
import { DownloadButtons } from './DownloadButtons/DownloadButtons';
import { SmartLoading } from './SmartLoading';
import { BackButton } from './BackButton/BackButton';

const QRNormal = lazy(() =>
  import(/* webpackPrefetch: true */ 'react-qrbtf').then((module) => ({
    default: module.QRNormal,
  }))
);
const QRLine = lazy(() =>
  import(/* webpackPrefetch: true */ 'react-qrbtf').then((module) => ({
    default: module.QRLine,
  }))
);
const ChromePicker = lazy(
  /*
  import react-color/lib/Chrome to reduce amount of code loaded
  https://medium.com/@christiango/the-unexpected-impact-of-dynamic-imports-on-tree-shaking-ddadeb135dd7
   */
  () => import(/* webpackPrefetch: true */ 'react-color/lib/Chrome')
);

const QRStep2 = () => {
  const [show, setShow] = useState(false);
  const [showcolor, setShowColor] = useState(false);

  const [openLoader, setLoader] = useState(false);

  const [url, setUrl] = useState('');
  const [url1, setUrl1] = useState('');

  const dispatch = useDispatch();

  const [show1, setShow1] = useState(false);
  const qrCode = useSelector((state) => state.text.qrCode);
  const mobileShowQrCode = useSelector((state) => state.text.mobileShowQrCode);
  const [selectedColor, setSelectedColor] = useState('#000000');
  const [selectedColor1, setSelectedColor1] = useState('#000000');
  const handleColorChange = (e) => {
    setSelectedColor(e.hex);
  };

  const handleColorChange1 = (e) => {
    setSelectedColor1(e.hex);
  };

  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = async (event) => {
        const base64Image = event.target.result;
        const { dataUrl, blob } = await getImageCenter(base64Image);
        setSelectedItem3({ img: dataUrl });
        setSocial([...social, { img: URL.createObjectURL(blob) }]);
        setIndexValue(social.length);
      };

      reader.readAsDataURL(file);
    }
  };

  const onBackOnMobile = () => {
    dispatch(goBackToEditOnMobile());
  };

  const data = [
    {
      img: Url,
      text: 'URL',
    },

    {
      img: Vcard,
      text: 'VCARD',
    },
    {
      img: Text,
      text: 'TEXT',
    },
    {
      img: SMS,
      text: 'SMS',
    },
    {
      img: Email,
      text: 'EMAIL',
    },
    {
      img: Wifi,
      text: 'WIFI',
    },
  ];
  const outerEye = [
    {
      img: E1,
      value: 'rect',
    },
    {
      img: E2,
      value: 'roundRect',
    },
    {
      img: E3,
      value: 'round',
    },
    {
      img: E4,
      value: 'planet',
    },
  ];

  const frame = [
    {
      img: logoImage,
      value: 'frame1',
    },
    {
      img: F2,
      value: 'frame2',
    },
    {
      img: F3,
      value: 'frame3',
    },
    {
      img: F4,
      value: 'frame4',
    },
  ];

  const bodyPatteren = [
    {
      img: P1,
      value: 'rect',
    },
    {
      img: P2,
      value: 'round',
    },
    {
      img: P3,
      value: 'rand',
    },
    {
      img: P4,
      value: 'left-right',
    },
    {
      img: P5,
      value: 'up-down',
    },
    {
      img: P6,
      value: 'loop',
    },

    {
      img: P7,
      value: 'topRight-bottomLeft',
    },

    {
      img: P8,
      value: 'topLeft-bottomRight',
    },
    {
      img: P9,
      value: 'cross',
    },
  ];

  const [social, setSocial] = useState([
    {
      img: C1,
    },
    {
      img: C2,
    },
    {
      img: C3,
    },
    {
      img: C4,
    },
    {
      img: C5,
    },
    {
      img: C6,
    },
    {
      img: C7,
    },
    {
      img: C8,
    },
    {
      img: C9,
    },
    {
      img: C10,
    },
    {
      img: C11,
    },
    {
      img: Tw,
    },
    {
      img: C12,
    },
    {
      img: C13,
    },
    {
      img: C14,
    },
    {
      img: C15,
    },
    {
      img: C16,
    },
    {
      img: C17,
    },
    {
      img: C19,
    },
  ]);

  const screenshotRef = useRef(null);

  const captureSVG = () => {
    const svgContainer = screenshotRef.current.querySelector('svg');
    // Convert the SVG container to an SVG string
    const svgString = new XMLSerializer().serializeToString(svgContainer);

    // Create an HTML <a> element to download the SVG
    const a = document.createElement('a');
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    a.href = URL.createObjectURL(blob);
    a.download = 'QR_Code_Generator_App.svg';
    a.click();
  };

  const [selectedItem, setSelectedItem] = useState(bodyPatteren[0]);
  const [selectedItem2, setSelectedItem2] = useState(outerEye[0]);
  const [selectedItem3, setSelectedItem3] = useState(social[0]);
  const [selectedItem4, setSelectedItem4] = useState(frame[0]);
  const [indexValue, setIndexValue] = useState(0);

  const [bodyPatternValue, setBodyPatternValue] = useState('rect');
  const [bodyPatternindex, setBodyPatternIndex] = useState(0);

  const handleItemClickBodyPattern = (item, index) => {
    setBodyPatternIndex(index);
    setSelectedItem(item);
    setBodyPatternValue(item.value);
  };

  const handleItemClickOuterEye = (item) => {
    setSelectedItem2(item);
  };
  const handleItemClickSocial = (item, index) => {
    setIndexValue(index);

    if (index === 0) return setSelectedItem3(social[0]);

    // Load the image from the URL
    fetch(social[index].img) // Replace with the actual URL
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const base64Image = event.target.result;
          setSelectedItem3({ ...item, img: base64Image });
        };

        reader.readAsDataURL(blob);
      });
  };
  const handleGenerateQR1 = async () => {
    if (!url1) {
      setShowColor(true);
      const { default: Swal } = await import(
        /* webpackPrefetch: true */ 'sweetalert2'
      );
      Swal.fire({
        icon: 'info',
        text: 'Please enter URL',
        confirmButtonColor: '#6C95FF',
      });
      return;
    }
    const qrCode = getUrlWithPrefix(url1);
    dispatch(setQrCode(qrCode));
  };

  const handleShow = () => {
    setShow(!show);
  };
  const handleShow1 = () => {
    setShow1(!show1);
  };

  useEffect(() => {
    if (qrCode) {
      setShow(true);
    }
  }, [qrCode]);

  const [selectedValue, setSelectedValue] = useState('URL');

  const handleItemClick = (value) => {
    setSelectedValue(value);
  };
  const renderComponent = () => {
    switch (selectedValue) {
      case 'URL':
        return <VCARD />;
      case 'VCARD':
        return <VCARD />;
      case 'TEXT':
        return <TextForQr />;
      case 'SMS':
        return <QRSMS />;
      case 'EMAIL':
        return <EmailForQR />;
      case 'WIFI':
        return <WifiQR />;
      default:
        return null; // You can render a default component here if needed
    }
  };

  useEffect(() => {
    if (url) {
      dispatch(setQrCode(getUrlWithPrefix(url)));
    }
  }, [dispatch, url]);
  const captureScreenshot = async () => {
    setLoader(true);
    try {
      const qrCodeWrapper = screenshotRef.current;

      const targetImageSize = 1500;
      const imageWrapperSize = qrCodeWrapper.getBoundingClientRect().width;
      const scaleValue = targetImageSize / imageWrapperSize;
      const { default: html2canvas } = await import(
        /* webpackPrefetch: true */ 'html2canvas'
      );

      html2canvas(qrCodeWrapper, {
        backgroundColor: 'transparent',
        scale: scaleValue,
      }).then(function (canvas) {
        const link = document.createElement('a');
        link.download = 'QR_Code_Generator_App.png';

        // Use 'image/png' for PNG format with transparency
        link.href = canvas.toDataURL('image/png');

        link.click();
      });
    } catch (error) {
      console.error('Error capturing screenshot:', error);
    } finally {
      setLoader(false);
    }
  };

  const imageValueforicon =
    selectedItem3.img !== social[0].img ? selectedItem3.img : null;

  useEffect(() => {
    if (openLoader) {
      setTimeout(() => {
        setLoader(false);
      }, 7000);
    }
  }, [openLoader]);

  const colorPickerRef = useRef(null);

  const [openPicker, setOpenPicker] = useState(false);
  const [openPicker1, setOpenPicker1] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpenPicker(false);
      setOpenPicker1(false);
    }, 4000);
  }, [openPicker, openPicker1]);

  useEffect(() => {
    setTimeout(() => {
      setOpenPicker1(false);
    }, 4000);
  }, [openPicker1]);

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [displayColorPicker1, setDisplayColorPicker1] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClick1 = () => {
    setDisplayColorPicker1(!displayColorPicker1);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  const handleClose1 = () => {
    setDisplayColorPicker1(false);
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  };

  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };

  return (
    <>
      {/* {openLoader ?


                <Modal
                    isOpen={true}
                    style={customStyles}
                    contentLabel="Loading Modal"
                >

                    <ModalForScan />


                </Modal> : ""
            }
 */}

      <div className="step1Main">
        <div className={`step1-main1 ${mobileShowQrCode ? 'new-div' : ''}  `}>
          <div className="item-container">
            {data.map((item) => (
              <div
                key={item.text}
                onClick={() => handleItemClick(item.text)}
                className={`item `}
              >
                <div
                  className={`item child-item ${
                    selectedValue === item.text ? 'active' : 'Noactive'
                  }`}
                >
                  <img
                    src={item.img}
                    alt=""
                    className="item-img"
                    style={{ padding: '10px' }}
                  />
                  <span className="item-text" style={{ padding: '10px' }}>
                    {item.text}
                  </span>
                </div>
              </div>
            ))}
          </div>

          {selectedValue === 'URL' ? (
            <div>
              <div className="url-text">Enter URL</div>
              <div className={showcolor ? 'red-border' : 'url-input'}>
                <input
                  placeholder="yourwebsite.com"
                  className="url-input"
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                />
              </div>

              <div className={showcolor ? 'red-border' : 'url-input1'}>
                <input
                  placeholder="yourwebsite.com"
                  className="url-input1"
                  onChange={(e) => {
                    setUrl1(e.target.value);
                  }}
                />
              </div>

              <div className="genCode">
                <GenerateButton onClick={handleGenerateQR1} />
              </div>
            </div>
          ) : (
            renderComponent()
          )}
        </div>

        <div className={`step1-main2  ${mobileShowQrCode ? '' : 'new-div'} `}>
          <BackButton onClick={onBackOnMobile} />
          <div className="screen-shot">
            <div className="screen-shot1">
              {/* <div className={selectedItem4.value === "frame1" || selectedItem4.value === "frame2" ? "display-none" : 'top-text-div top-text-div1'} style={
                                selectedItem4.value === 'frame1' || selectedItem4.value === 'frame2'
                                    ? { display: 'none' }
                                    : { backgroundColor: selectedColor2 }
                            }   >
                                {inputValues.topText}

                            </div> */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderLeft:
                    selectedItem4.value === 'frame1'
                      ? '0px solid white'
                      : `29px solid #000000`,
                  borderRight:
                    selectedItem4.value === 'frame1'
                      ? '0px solid white'
                      : `29px solid #000000`,
                  borderTop:
                    selectedItem4.value === 'frame2'
                      ? `15px solid #000000`
                      : 'none',
                  borderBottom:
                    selectedItem4.value === 'frame3'
                      ? `15px solid #000000`
                      : 'none',
                }}
                className={
                  selectedItem4.value === 'frame1'
                    ? 'div-border-style1'
                    : 'div-border-style'
                }
              >
                {qrCode ? (
                  <div className="Qr-img">
                    <div id="table-container" ref={screenshotRef}>
                      <Suspense
                        fallback={
                          <div className="loading-wrapper">
                            <span>
                              <SmartLoading>Loading QR Code...</SmartLoading>
                            </span>
                          </div>
                        }
                      >
                        {bodyPatternindex == 0 ||
                        bodyPatternindex == 1 ||
                        bodyPatternindex == 2 ? (
                          <QRNormal
                            value={qrCode}
                            style={{ svg: { width: '400px', height: '400px' } }}
                            size={100}
                            opacity={100}
                            type={bodyPatternValue}
                            posType={selectedItem2.value}
                            posColor={selectedColor1}
                            level="M"
                            icon={imageValueforicon}
                            iconScale={0.3}
                            otherColor={selectedColor}
                          />
                        ) : (
                          <QRLine
                            value={qrCode}
                            style={{ svg: { width: '400px', height: '400px' } }}
                            size={50}
                            direction={bodyPatternValue}
                            opacity={100}
                            posType={selectedItem2.value}
                            lineWidth={50}
                            icon={
                              selectedItem3.img !== social[0].img
                                ? selectedItem3.img
                                : null
                            }
                            lineColor={selectedColor}
                            posColor={selectedColor1}
                            iconScale={0.3}
                          />
                        )}
                      </Suspense>
                    </div>
                  </div>
                ) : (
                  <img src={QR} className="qr-img" alt="QR Code" />
                )}
              </div>

              {/* <div className={selectedItem4.value === "frame1" || selectedItem4.value === "frame3" ? "display-none" : 'top-text-div top-text-div1'} style={
                                selectedItem4.value === 'frame1' || selectedItem4.value === 'frame3'
                                    ? { display: 'none' }
                                    : { backgroundColor: selectedColor5 }
                            }  >
                                {inputValues.bottomText}

                            </div> */}
            </div>
          </div>
          <div className={show ? 'p-background' : 'p1-background'}>
            <div className="plus-main">
              <div className="plus-text" onClick={handleShow}>
                PATTERN & LOGO
              </div>
              <img
                src={show ? Close : Plus}
                onClick={handleShow}
                className="plus-img"
              />
            </div>
            {show ? (
              <div className="body-pattern-main">
                <div
                  className={qrCode ? 'b-p-text' : 'b-p-text'}
                  style={{ padding: '10px' }}
                >
                  Body Pattern
                </div>

                <div className="image-container">
                  {bodyPatteren.map((item, index) => (
                    <img
                      key={index}
                      src={item.img}
                      className={`b-pattern ${
                        item.img === selectedItem.img ? 'selected' : ''
                      }`}
                      alt={`Pattern ${index}`}
                      onClick={() => handleItemClickBodyPattern(item, index)}
                    />
                  ))}
                </div>
                <div
                  className={qrCode ? 'b-p-text' : 'b-p-text'}
                  style={{ padding: '10px' }}
                >
                  Eye Shape
                </div>
                <div className="image-container">
                  {outerEye.map((item, index) => (
                    <img
                      key={index}
                      src={item.img}
                      className={`b-pattern ${
                        item.img === selectedItem2.img ? 'selected' : ''
                      }`}
                      alt={`Pattern ${index}`}
                      onClick={() => handleItemClickOuterEye(item)}
                    />
                  ))}
                </div>
                {/* <div className={qrCode ? "b-p-text" : 'b-p-text'} style={{ padding: '10px' }}>
                                    Inner Eye Shape
                                </div>
                                <div className="image-container" style={{ padding: '10px' }}>
                                    {innerEye.map((item, index) => (
                                        <img
                                            key={index}
                                            src={item.img}
                                            className={`b-pattern ${item.img === selectedItem1.img ? 'selected' : ''}`}
                                            alt={`Pattern ${index}`}
                                            onClick={() => handleItemClickInnerEye(item)}
                                        />
                                    ))}
                                </div> */}
                {/* {
                        showModal ?
                            <ModalForScan /> : ""
                    } */}
                <div className="b-p-text" style={{ padding: '10px' }}>
                  Logo
                </div>
                <div className="u-div" style={{ padding: '10px' }}>
                  <label htmlFor="fileInput" className="custom-file-input">
                    <div className="div-img">
                      <>
                        <div className="div-file">
                          Upload
                          <br />
                          Image
                        </div>
                      </>
                    </div>{' '}
                    <input
                      id="fileInput"
                      type="file"
                      accept=".png, .svg, .jpeg, .jpg"
                      onChange={handleFileChange1}
                    />
                  </label>

                  {/* <div className="custom-file-input1">
                                        <label htmlFor="fileInput" className="custom-button">
                                            UPLOAD IMAGE
                                        </label>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            accept="image/jpeg, image/png" // Specify the accepted file types
                                            onChange={handleFileChange1}
                                            style={{ display: 'none' }}
                                        />
                                    </div> */}
                </div>

                <div className="image-container">
                  {social.map((item, index) => (
                    <img
                      key={index}
                      src={item.img}
                      className={`b-pattern ${
                        index === indexValue ? 'selected' : ''
                      }`}
                      alt={`Pattern ${index}`}
                      onClick={() => handleItemClickSocial(item, index)}
                    />
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div
            className={show1 ? 'p-background' : 'p1-background'}
            style={{ marginTop: '20px' }}
          >
            <div className="plus-main2">
              <div className="plus-text" onClick={handleShow1}>
                COLOR
              </div>
              <img
                src={show1 ? Close : Plus}
                onClick={handleShow1}
                className="plus-img"
              />
            </div>

            {show1 ? (
              <div className={show1 ? 'p-background' : 'p1-background'}>
                <div className="body-color">Body Pattern Color</div>
                <div className="color-picker">
                  <div
                    className="pick1"
                    onClick={handleClick}
                    style={{ background: selectedColor }}
                  ></div>
                  {displayColorPicker ? (
                    <div style={popover}>
                      <div style={cover} onClick={handleClose} />
                      <Suspense fallback={<SmartLoading />}>
                        <ChromePicker
                          // type="color"
                          color={selectedColor}
                          onChange={handleColorChange}
                          ref={colorPickerRef}
                        />
                      </Suspense>
                    </div>
                  ) : null}

                  <div className="color-text" onClick={handleClick}>
                    {selectedColor}
                  </div>
                </div>
                {/* <div className='Gradient'>

                                    <label className="switch">
                                        <input type="checkbox" checked={isChecked}
                                            onChange={onCheckboxChange} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className='Gradient-text'>
                                        Gradient
                                    </div>


                                </div> */}
                {/* <div className='gradiant-typeptext'>
                                    Gradient Type
                                </div>
                                <div className="custom-dropdown">
                                    <select
                                        className={`wifiInput `}
                                        value={gradiantType}
                                        onChange={handleInput2Change}
                                    >
                                        <option value='tb' className='option-text'>Top To Bottom</option>
                                        <option value='bt' className='option-text'>Bottom To Top</option>
                                        <option value='lr' className='option-text'>Left To Right</option>
                                        <option value='rl' className='option-text'>Right To Left</option>

                                    </select>
                                </div> */}

                <div>
                  {/* <div className='body-color'>Second Color</div>
                                    <div className='color-picker'>

                                        <div>

                                            <input
                                                type="color"
                                                value={selectedColor1}
                                                onChange={handleColorChange1}
                                            />
                                        </div>
                                        <div className='color-text'>

                                            {selectedColor1}
                                        </div>
                                    </div> */}
                  <div className="body-color" style={{ marginTop: '10px' }}>
                    Eye Color
                  </div>
                  <div className="color-picker">
                    <div
                      className="pick1"
                      onClick={handleClick1}
                      style={{ background: selectedColor1 }}
                    ></div>
                    {displayColorPicker1 ? (
                      <div style={popover}>
                        <div style={cover} onClick={handleClose1} />
                        <Suspense fallback={<SmartLoading />}>
                          <ChromePicker
                            color={selectedColor1}
                            onChange={handleColorChange1}
                          />
                        </Suspense>
                      </div>
                    ) : null}

                    <div className="color-text" onClick={handleClick1}>
                      {selectedColor1}
                    </div>
                  </div>
                  {/* <div className='body-color'>Inner Eye Color </div>
                                    <div className='color-picker'>

                                        <div>

                                            <input
                                                type="color"
                                                value={selectedColor3}
                                                onChange={handleColorChange3}
                                            />
                                        </div>
                                        <div className='color-text'>

                                            {selectedColor3}
                                        </div>
                                    </div> */}
                  {/* <div className='body-color'>
                                        Frame
                                    </div>
                                    <div className='frame-main'>
                                        {
                                            frame.map((item, index) => {
                                                return <>
                                                    <img key={index} src={item.img} width={isMobile ? '60px' : '100px'} height={isMobile ? '60px' : '100px'} className={`"f-img" ${item.img === selectedItem4.img ? 'selected' : ''}`}
                                                        alt={`Pattern ${index}`}
                                                        onClick={() => handleItemClickFrame(item)} />
                                                </>
                                            })
                                        }
                                    </div> */}

                  {/* {
                                        selectedItem4.value === "frame4" || selectedItem4.value === "frame3" ? <div>
                                            <div className='top-text'>Top Text</div>
                                            <input
                                                className='top-text-input'
                                                type='text'
                                                name='topText'
                                                value={inputValues.topText}
                                                onChange={handleChange}
                                            />
                                        </div> : ""
                                    } */}

                  {/* {

                                        selectedItem4.value === "frame1" || selectedItem4.value === "frame3" ? "" : <div>
                                            <div className='top-text'>Bottom Text</div>
                                            <input
                                                className='top-text-input'
                                                type='text'
                                                name='bottomText'
                                                value={inputValues.bottomText}
                                                onChange={handleChange}
                                            />
                                        </div>

                                    } */}
                </div>

                {/* {

                                    selectedItem4.value === "frame4" || selectedItem4.value === "frame3" ? <div className='body-color'>Top Text Background Color </div>
                                        : ""


                                } */}

                {/* {

                                    selectedItem4.value === "frame4" || selectedItem4.value === "frame3" ?
                                        <div className='color-picker'>



                                            <div className="pick1" onClick={handleClick2} style={{ background: selectedColor2 }} >

                                            </div>
                                            {displayColorPicker2 ? (
                                                <div style={popover}>
                                                    <div style={cover} onClick={handleClose2} />
                                                    <ChromePicker
                                                        color={selectedColor2}
                                                        onChange={handleColorChange2}
                                                    />
                                                </div>
                                            ) : null}



                                            <div className='color-text' onClick={handleClick2} >

                                                {selectedColor2}
                                            </div>
                                        </div> : ""

                                } */}

                {/* {

                                    selectedItem4.value === "frame4" || selectedItem4.value === "frame2" ?

                                        <>


                                            <div className='body-color'>Bottom Text Background Color</div>
                                            <div className='color-picker'>



                                                <div className="pick1" onClick={handleClick3} style={{ background: selectedColor5 }} >

                                                </div>
                                                {displayColorPicker3 ? (
                                                    <div style={popover}>
                                                        <div style={cover} onClick={handleClose3} />
                                                        <ChromePicker
                                                            color={selectedColor5}
                                                            onChange={handleColorChange3}
                                                        />
                                                    </div>
                                                ) : null}



                                                <div className='color-text' onClick={handleClick3} >

                                                    {selectedColor5}
                                                </div>
                                            </div>


                                        </> : ""

                                } */}

                {/* {

                                    selectedItem4.value !== "frame1" ? <>
                                        <div className='body-color'>Frame Background Color</div>
                                        <div className='color-picker'>



                                            <div className="pick1" onClick={handleClick4} style={{ background: selectedColor6 }} >

                                            </div>
                                            {displayColorPicker4 ? (
                                                <div style={popover}>
                                                    <div style={cover} onClick={handleClose4} />
                                                    <ChromePicker
                                                        color={selectedColor6}
                                                        onChange={handleColorChange6}
                                                    />
                                                </div>
                                            ) : null}



                                            <div className='color-text' onClick={handleClick4} >

                                                {selectedColor6}
                                            </div>
                                        </div>


                                    </> : ""

                                } */}
              </div>
            ) : (
              ''
            )}
          </div>
          <DownloadButtons
            onDownloadPng={captureScreenshot}
            disabled={!qrCode}
            onDownloadSvg={captureSVG}
          />
          <br />
          <p className="p-gr">
            My fiancée said I need to help pay for our wedding next year. If you
            can help, even if it’s $1, there are pay what you can links below.
          </p>
          <br />{' '}
          <div className="ps">
            <a
              href="https://www.paypal.com/donate/?hosted_button_id=QEJNTS5X4AFU6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Pay2} className="pay-img" />
            </a>

            <a
              href="https://donate.stripe.com/4gweXh83vfqhfHG9AA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ST} className="pay-img" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRStep2;
